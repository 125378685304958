import {
  Typography,
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Image from 'next/image';

import * as Styles from './styles';

export default function Hero() {
  const theme = useTheme();
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box
      color="primary.contrastText"
      id="hero"
      component="section"
      sx={{
        backgroundImage: {
          xs: 'url(https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1729890177/Black%20November/bg-school-component_sjhlmm)',
          md: 'url(https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1730126379/Black%20November/bg-banner-abroad_pbvwoc)',
          lg: 'url(https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1730232187/Black%20November/BG_home_eunsis)',
        },
        backgroundColor: { xs: '#161616', md: '#151515' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { xs: 'auto', xl: 'cover' },
        backgroundPosition: { xs: '40%', md: 'inherit', xl: '8%' },
        backgroundPositionY: { xs: 'top', md: '-4%', xl: 'inherit' },
        backgroundPositionX: { md: '17%', lg: 'none', xl: '15%', xxl: '132px' },
        minHeight: { xs: '460px', md: 'auto' },
      }}
    >
      <Container maxWidth="xl" disableGutters sx={Styles.SectionWrapper}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Box
              height={{ xs: 70, lg: 120 }}
              position="relative"
              width={{ xs: 220, lg: 320 }}
              sx={{ ml: { xs: 0, sm: 2, md: -2, lg: -3, xl: 0 } }}
            >
              <Image
                alt="Black november logo"
                fill
                loader={({ src, width }) => `${src}?w=${width}`}
                src="https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1729793566/Black%20November/logo-black-november_p0dvys"
                style={{
                  objectFit: 'contain',
                  marginLeft: xlBreakpoint && '-30px',
                  marginTop: xlBreakpoint && '30px',
                }}
              />
            </Box>

            <Box sx={Styles.TextWrapper} width="100%">
              <Typography
                component="h1"
                fontWeight={700}
                variant="h2"
                sx={{
                  lineHeight: 1.2,
                }}
              >
                Inglês +<br />
                Intercâmbio
              </Typography>

              <Typography
                component="h2"
                maxWidth="585px"
                mt={1}
                variant="subtitle1"
              >
                O ciclo completo para você falar inglês definitivamente. Aulas
                24/7, conversação ilimitada, aula particular e intercâmbio.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box
              sx={{
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: { lg: 'right', xl: 'flex-start' },
                height: '100%',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  height: { xs: 450, xl: 460 },
                  position: 'relative',
                  width: { xs: '100%', lg: 450, xl: 600 },
                }}
              >
                <Image
                  alt="Mulher com o ciclo completo"
                  fill
                  loader={({ src, width }) => `${src}?w=${width}`}
                  src="https://res.cloudinary.com/intercambiodireto-com/image/upload/f_auto/v1730229381/Black%20November/woman-home_lwqvpk"
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
